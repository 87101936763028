<template>
  <div class="welcome">
    <div class="welcome__logo-container">
      <div class="welcome__logo-wrapper">
        <main-logo :tied-agent="tiedAgent" />
      </div>
    </div>
    <single-choice
      class="welcome__single-choice"
      :alternatives="alternatives"
      @click="buttonClick"
    >
      <information-text-section
        invisible-image
        heading="Just nu tar vi inte emot några nya kunder."
        body="Detta med anledning av Avanzas förvärv av Sigmastocks. Mer information kommer framöver!"
        body2="För dig med lite större sparkapital hör av dig till oss på support@sigmastocks.com."
      />
    </single-choice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { LogLevel, TiedAgent } from "@/clients";
import { trackEvent, trackEventOnlyGa4 } from "@/clients/segment";
import log from "@/clients/log";
import MainLogo from "@/components/main-logo.vue";
import SingleChoice from "../templates/single-choice.vue";
import InformationTextSection from "../information-text-section.vue";
import Question from "../templates/question";

const logger = "welcome.vue";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice,
    MainLogo
  },
  extends: Question,
  data(): any {
    return {
      logoUrl: new URL("/assets/images/logos/sigmastocks-logo-text.svg", import.meta.url).href
    };
  },
  computed: {
    tiedAgent(): TiedAgent {
      return this.$store.state.signupStore.tiedAgent;
    },
    alternatives() {
      return [
        {
          text: this.$t("back"),
          key: "back"
        }
      ];
    }
  },
  mounted() {
    log(logger, "ad_start_signup", LogLevel.Information);
    trackEvent("start_signup");
    trackEventOnlyGa4("conversion", { send_to: "AW-966604819/OhNBCNCExNECEJPw9MwD" });
  },

  methods: {
    buttonClick() {
      //this.$router.push({ name: "login" });

      // While signup is closed, redirect away on back.
      window.location.href = "https://www.sigmastocks.com/";
    },
    goToCompanySignup() {
      // Use replace otherwise the router will not work if the user clicks the back button in the browser.
      // This way the back button leads to the start view (/signup).
      this.$router.replace({ name: "company-signup" });
    }
  }
});
</script>

<style lang="scss" scoped>
.welcome {
  height: 100%;
  display: flex;
  flex-direction: column;
  @include medium-up {
    max-height: $signup-desktop-max-height;
  }
  &__single-choice {
    padding-bottom: 1rem;
  }
  &__logo-container {
    margin-top: 1rem;
    height: 3rem;
    display: block;
    margin-top: 1rem;
  }
  &__logo {
    path {
      fill: $soft-blue;
      opacity: 1;
    }
  }
  &__logo-wrapper {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
  &__link-container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &__create-account-text {
    color: $nearly-black;
    opacity: 0.63;
    padding-right: 0.25rem;
  }
  &__link {
    opacity: 0.63;
    color: $nearly-black;
    text-decoration: underline;
  }
}
</style>
