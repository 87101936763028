import type { ActionTree } from "vuex";
import { OverviewMutation } from "./mutations";
import type { OverviewState } from "./types";
import { type PortfolioBase, PortfolioPerformanceClient } from "@/clients";
import { baseUrl } from "@/clients/config";
import type { RootState } from "@/types";
import type { LegalEntityPortfolioIds } from "@/types/user";

const performanceClient = new PortfolioPerformanceClient(baseUrl);

export const OverviewActions = {
  loadCurrentLegalEntityPerformances: "loadCurrentLegalEntityPerformances",
  loadPerformances: "loadPerformances",
};

function loadPerformance(
  state: OverviewState,
  legalEntityId: string,
  portfolioId: string,
  commit: any,
  forceReload: boolean = false,
) {
  const isPerformanceLoaded = state.portfolioPerformances[portfolioId];
  const isPerformanceLoading = state.performanceLoading[portfolioId];

  if ((isPerformanceLoaded && !forceReload) || isPerformanceLoading) {
    return;
  }

  commit(OverviewMutation.setPerformanceLoading, { portfolioId, loading: true });

  performanceClient
    .get(legalEntityId, portfolioId, undefined, undefined, true)
    .then((x) => {
      commit(OverviewMutation.setPerformanceLoading, {
        portfolioId,
        loading: false,
      });
      commit(OverviewMutation.setPerformance, {
        portfolioId,
        performance: x,
      });
    })
    .catch((_) => {
      commit(OverviewMutation.setPerformanceLoading, {
        portfolioId,
        loading: false,
      });
    });
}

export const actions: ActionTree<OverviewState, RootState> = {
  async [OverviewActions.loadCurrentLegalEntityPerformances]({
    state,
    commit,
    getters,
    rootState,
  }) {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }

    for (const portfolio of rootState.navigationStore.portfolios as PortfolioBase[]) {
      loadPerformance(
        state,
        rootState.userStore.currentLegalEntity.brickId,
        portfolio.id,
        commit,
        getters.tokenUserInfo.testNewFeatures,
      );
    }
  },
  async [OverviewActions.loadPerformances](
    { state, commit },
    payload: {
      legalEntitiesPortfolioIds: LegalEntityPortfolioIds[];
      forceReload: boolean;
    },
  ) {
    for (const legalEntityPortfolioIds of payload.legalEntitiesPortfolioIds) {
      for (const portfolioId of legalEntityPortfolioIds.portfolioIds) {
        loadPerformance(
          state,
          legalEntityPortfolioIds.legalEntityId,
          portfolioId,
          commit,
          payload.forceReload,
        );
      }
    }
  },
};
