<template>
  <div class="tink-info">
    <TinkIframe
      @complete="handleTinkComplete"
      @error="handleTinkError"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { E2E_KEY } from "@/config/general";
import TinkIframe from "@/components/tink-iframe.vue";
import { SignupAction } from "@/store/signup/actions";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import Question from "@/views/signup/templates/question";

export default defineComponent({
  components: { TinkIframe },
  extends: Question,
  data: () => ({
    tinkPath: "",
    callbackFunc: null as any,
  }),
  unmounted() {
    window.removeEventListener("message", this.callbackFunc);
  },
  async mounted() {
    if (sessionStorage.getItem(E2E_KEY)) {
      await this.$store.dispatch(SignupAction.getAccountDetails, "12345");
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      return;
    }

    this.$store.commit(SignupMutation.setTinkError, false);
  },
  methods: {
    async handleTinkComplete(verificationId): Promise<void> {
      this.$store.commit(SignupMutation.setTinkError, false);
      await this.$store.dispatch(SignupAction.getAccountDetails, verificationId);
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    handleTinkError(): void {
      this.$store.commit(SignupMutation.setTinkError, true);
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
  },
});
</script>

<style lang="scss" scoped>
.tink-info {
  width: 100%;
  height: 100%;
  @include medium-up {
    max-height: 700px;
  }
}
.tink-iframe {
  padding-top: 1rem;
  border-style: none;
  width: 100%;
  height: 100%;
}
</style>
