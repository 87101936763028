<template>
  <iframe :src="tinkPath" />
</template>

<script setup lang="ts">
import { defineEmits, onMounted, onUnmounted } from "vue";
import * as Sentry from "@sentry/vue";
import type { SeverityLevel } from "@sentry/types";

const emit = defineEmits<{
  (event: "complete", payload: string): void;
  (event: "error"): void;
}>();

const isDevelopment = import.meta.env.VITE_ENV === "development";
const isProduction = import.meta.env.VITE_ENV === "production";
const encodedBaseUrl = encodeURIComponent(import.meta.env.VITE_WEB_BASE_URL as string);

const tinkPath = isProduction
  ? `https://link.tink.com/1.0/account-check?client_id=eab901e187df45c3b470145117eaf4de&redirect_uri=${encodedBaseUrl}&market=SE&locale=sv_SE&iframe=true&refreshable_items=CHECKING_ACCOUNTS`
  : `https://link.tink.com/1.0/account-check?client_id=b55f3076cf2c4e26a6b655b6b23217bc&redirect_uri=${encodedBaseUrl}&market=SE&locale=sv_SE&test=true&input_provider=se-demobank-open-banking-bankid&iframe=true&refreshable_items=CHECKING_ACCOUNTS`;

const allowedOrigin = "https://link.tink.com";

const logMessage = (message: string, data: any, level: "error" | "warning" | "info") => {
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console[level](message, data);
  } else {
    Sentry.captureMessage(message, {
      level: level as SeverityLevel,
      contexts: { rawEvent: { data } },
    });
  }
};

// Event types and details can be found here:
// https://docs.us.tink.com/resources/sdk/sdk-iframe
const handlePostMessage = (event: MessageEvent) => {
  if (event.origin !== allowedOrigin) {
    return;
  }

  let parsedData;
  try {
    parsedData = JSON.parse(event.data);
  } catch (e) {
    logMessage("Failed to parse message data as JSON", event.data, "error");
    return;
  }

  const { type, data, error } = parsedData;

  if (error) {
    logMessage("Tink error", event.data, "error");
    emit("error");
    return;
  }

  switch (type) {
    case "account_verification_report_id":
      emit("complete", data);
      break;
    default:
      if (isDevelopment) {
        console.warn(`Unhandled message type: ${type}`, data);
      }
  }
};

onMounted(() => {
  window.addEventListener("message", handlePostMessage);
});

onUnmounted(() => {
  window.removeEventListener("message", handlePostMessage);
});
</script>

<style scoped>
iframe {
  height: 100%;
  width: 100%;
}
</style>
