<template>
  <div class="dropdown">
    <div class="dropdown__header">
      <h2 class="dropdown__title">
        {{ $t("components.mobile-menu.dropdown.menu") }}
      </h2>
      <v-btn
        class="dropdown__header-button dropdown__header-button--right"
        variant="flat"
        icon
        @click="close"
      >
        <icon
          fixed-width
          :icon="['fal', 'times']"
        />
      </v-btn>
    </div>

    <v-list
      theme="dark"
      class="dropdown__list"
    >
      <dropdown-item
        :title="$t('overview')"
        @click="goToRoute({ name: 'overview' })"
      >
        <template #prepended-icon>
          <img :src="loadImageByName('icon_overview.svg')" />
        </template>
      </dropdown-item>

      <dropdown-item
        v-for="(portfolio, index) in portfolios"
        :key="index"
        :ref="`${portfolio.id}`"
        :data-cy="'dropdownPortfolio' + index"
        :title="portfolio.name"
        indicate-clickability-icon
        @click="goToRoute({ name: 'portfolio', params: { id: portfolio.id } })"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="['fal', 'briefcase']"
          />
        </template>
      </dropdown-item>

      <dropdown-item
        v-for="(portfolio, pensionIndex) in pensionPortfolios"
        :key="pensionIndex + 'bar'"
        :title="portfolio.name"
        :to="{ name: 'portfolio', params: { id: portfolio.id } }"
        badge-inline
      >
        <template #prepended-icon>
          <img :src="loadImageByName('pension-pyramid.svg')" />
        </template>
        <template #badge-icon>
          <img
            v-if="portfolio.inProgressPensions && pensionInProgress(portfolio.inProgressPensions)"
            :src="loadImageByName('loading-full-blue.svg')"
          />
          <img
            v-else-if="
              portfolio.inProgressPensions &&
              pensionRequiresSupplementaryInfo(portfolio.inProgressPensions)
            "
            :src="loadImageByName('red-exclamation.svg')"
          />
        </template>
      </dropdown-item>

      <dropdown-item
        v-if="!isCompany"
        :disabled="numberOfPortfolios >= 5 || !answeredNewEsg"
        :to="{ name: 'create-portfolio' }"
        :title="'Ny portfölj'"
        aria-label="create-portfolio"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="['fal', 'plus-circle']"
          />
        </template>
      </dropdown-item>

      <!-- <dropdown-item
        v-if="!isCompany"
        :disabled="!answeredNewEsg"
        :title="$t('movePension')"
        :to="{ name: 'pension' }"
        aria-label="pension"
      >
        <template #prepended-icon>
          <img :src="loadImageByName('pension-pyramid.svg')" />
        </template>
      </dropdown-item> -->

      <dropdown-item
        v-if="!isCompany"
        :title="$t('inviteFriend')"
        @click="$emit('click-referrals')"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="['fal', 'heart']"
          />
        </template>
      </dropdown-item>

      <dropdown-item
        v-if="isCompany"
        :title="$t('profile')"
        @click="goToRoute({ name: 'company-profile' })"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="['fal', 'building']"
          />
        </template>
      </dropdown-item>
      <dropdown-item
        v-else
        :title="$t('profile')"
        @click="goToRoute({ name: 'profile' })"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="['fal', 'user']"
          />
        </template>
      </dropdown-item>

      <dropdown-item
        :badge="unreadMessagesCount > 0"
        :title="$t('messages') + (unreadMessagesCount > 0 ? ' (' + unreadMessagesCount + ')' : '')"
        @click="goToRoute({ name: 'messages' })"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="[unreadMessagesCount > 0 ? 'fas' : 'fal', 'envelope']"
          />
        </template>
      </dropdown-item>
      <dropdown-item
        :title="$t('signOut')"
        @click="$emit('click-signout')"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="['fal', 'sign-out']"
          />
        </template>
      </dropdown-item>
      <dropdown-item
        :title="$t('changeAccount')"
        @click="goToRoute({ name: 'choose-legal-entity', query: { switchAccount: 'true' } })"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="['fal', 'sync']"
          />
        </template>
      </dropdown-item>
    </v-list>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { RouteLocationRaw } from "vue-router";
import { InProgressPension, PortfolioBase, PortfolioType } from "@/clients";
import { NavigationState } from "@/store/navigation/types";
import DropdownItem from "./dropdown-item.vue";
export default defineComponent({
  emits: ["click-referrals", "click-signout", "close"],
  components: {
    // OverviewIcon,
    DropdownItem
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "fullName",
      "unreadMessagesCount",
      "isCompany",
      "pensionPortfolios",
      "isTestUser",
      "answeredNewEsg"
    ]),
    portfolios(): PortfolioBase[] | undefined {
      return (this.$store.state.navigationStore.portfolios as PortfolioBase[]).filter(
        (x) => x.portfolioType !== PortfolioType.OccupationalPension
      );
    },
    numberOfPortfolios(): number {
      const { portfolios } = this.$store.state.navigationStore as NavigationState;
      if (portfolios) {
        return portfolios.filter((x) => x.portfolioType !== PortfolioType.OccupationalPension)
          .length;
      }
      return 0;
    }
  },
  methods: {
    loadImageByName(name: string) {
      return new URL(`/assets/icons/${name}`, import.meta.url).href;
    },
    close() {
      this.$emit("close");
    },
    goToRoute(location: RouteLocationRaw) {
      this.$router.push(location).catch((err) => {});
      this.close();
    },
    pensionInProgress(pensions: InProgressPension[]): boolean {
      return pensions.some((x) => this.$store.getters.pensionInProgress(x.status));
    },
    pensionRequiresSupplementaryInfo(pensions: InProgressPension[]): boolean {
      return pensions.some((x) => this.$store.getters.pensionRequiresSupplementaryInfo(x.status));
    }
  }
});
</script>

<style lang="scss" scoped>
.dropdown {
  color: white;
  background-color: $nearly-black;
  box-shadow: 0 0.1875rem 1.25rem rgba(0, 0, 0, 0.2);
  min-width: 15.625rem;
  min-height: 28.375rem;
  align-items: center;
  &__header {
    display: grid;
    grid-template-columns: 4rem auto 4rem;
    padding: 0.5rem;
  }
  &__header-button {
    font-size: 1rem;
    background-color: transparent;
    &--right {
      place-self: flex-end;
    }
  }
  &__list {
    background-color: $nearly-black;
    &--absolute {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
  &__list-wrapper {
    position: relative;
  }
  &__title {
    font-size: 1rem;
    font-weight: 600;
    grid-column: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
