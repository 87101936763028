<template>
  <div class="sidebar">
    <div class="sidebar__top-buttons">
      <v-tooltip
        location="bottom"
        open-delay="700"
        transition="fade-transition"
      >
        <template #activator="{ props }">
          <v-btn
            class="sidebar__button"
            icon
            v-bind="props"
            variant="flat"
            @click="$emit('click-signout')"
          >
            <icon
              class="sidebar__button-icon fa-flip-horizontal"
              fixed-width
              :icon="['fal', 'sign-out']"
            />
          </v-btn>
        </template>
        <span>{{ $t("signOut") }}</span>
      </v-tooltip>
      <v-tooltip
        location="bottom"
        open-delay="700"
        transition="fade-transition"
      >
        <template
          v-if="legalEntities && legalEntities.length > 1"
          #activator="{ props }"
        >
          <v-btn
            class="sidebar__button sidebar__top-buttons--change-account"
            icon
            v-bind="props"
            variant="flat"
            @click="goToRoute({ name: 'choose-legal-entity', query: { switchAccount: 'true' } })"
          >
            <icon
              class="sidebar__button-icon fa-flip-horizontal"
              fixed-width
              :icon="['fal', 'sync']"
            />
          </v-btn>
        </template>
        <span>{{ $t("changeAccount") }}</span>
      </v-tooltip>
      <v-tooltip
        location="bottom"
        open-delay="700"
        transition="fade-transition"
      >
        <template #activator="{ props }">
          <div>
            <span
              v-if="unreadMessagesCount > 0"
              class="sidebar__message-count-text"
              >({{ unreadMessagesCount }})</span
            >
            <v-btn
              class="sidebar__button"
              icon
              @click="goToRoute({ name: 'messages' })"
              variant="flat"
              v-bind="props"
            >
              <v-badge
                :model-value="unreadMessagesCount > 0"
                color="#FF0000"
                dot
              >
                <icon
                  class="sidebar__button-icon"
                  fixed-width
                  :icon="[unreadMessagesCount > 0 ? 'fas' : 'fal', 'envelope']"
                />
              </v-badge>
            </v-btn>
          </div>
        </template>
        <span>{{ $t("messages") }}</span>
      </v-tooltip>
    </div>
    <div class="sidebar__user">
      <icon
        fixed-width
        :icon="['fal', 'user-circle']"
        class="sidebar__icon"
      />
    </div>
    <div :class="['sidebar__name', { 'sidebar__name--small': nameTextSmall }]">
      <span> {{ firstName }} </span>
    </div>
    <v-divider
      style="border-color: #ffffff; opacity: 0.1; margin-top: 0.5rem; margin-bottom: 0.5rem"
    />
    <v-list
      theme="dark"
      class="sidebar__list"
    >
      <sidebar-item
        :title="$t('overview')"
        :to="{ name: 'overview' }"
      >
        <template #prepended-icon>
          <img :src="loadImageByName('icon_overview.svg')" />
        </template>
      </sidebar-item>

      <sidebar-item
        v-for="(portfolio, portfolioIndex) in portfolios"
        :key="portfolioIndex + 'foo'"
        :ref="`${portfolio.id}`"
        :title="portfolio.name"
        :data-cy="'portfolio' + portfolioIndex"
        :to="{ name: 'portfolio', params: { id: portfolio.id } }"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="['fal', 'briefcase']"
          />
        </template>
      </sidebar-item>
      <sidebar-item
        v-for="(portfolio, pensionIndex) in pensionPortfolios"
        :key="pensionIndex + 'bar'"
        :title="portfolio.name"
        :to="{ name: 'portfolio', params: { id: portfolio.id } }"
        badge-inline
      >
        <template #prepended-icon>
          <img :src="loadImageByName('pension-pyramid.svg')" />
        </template>
        <template #appended-icon>
          <img
            v-if="portfolio.inProgressPensions && pensionInProgress(portfolio.inProgressPensions)"
            :src="loadImageByName('loading-full-blue.svg')"
          />
          <img
            v-else-if="
              portfolio.inProgressPensions &&
              pensionRequiresSupplementaryInfo(portfolio.inProgressPensions)
            "
            :src="loadImageByName('red-exclamation.svg')"
          />
        </template>
      </sidebar-item>
      <sidebar-item
        v-if="isCompany"
        :to="{ name: 'company-profile' }"
        :title="$t('profile')"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="['fal', 'building']"
          />
        </template>
      </sidebar-item>
      <sidebar-item
        v-else
        :to="{ name: 'profile' }"
        :title="$t('profile')"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="['fal', 'user']"
          />
        </template>
      </sidebar-item>

      <v-divider style="border-color: #ffffff; opacity: 0.1" />

      <sidebar-item
        v-if="!isCompany"
        :disabled="numberOfPortfolios >= 5 || !answeredNewEsg"
        :to="{ name: 'create-portfolio' }"
        :title="'Ny portfölj'"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="['fal', 'plus-circle']"
          />
        </template>
      </sidebar-item>

      <!-- <sidebar-item
        v-if="!isCompany"
        :disabled="!answeredNewEsg"
        :to="{ name: 'pension' }"
        :title="$t('movePension')"
      >
        <template #prepended-icon>
          <img :src="loadImageByName('pension-pyramid.svg')" />
        </template>
      </sidebar-item> -->

      <sidebar-item
        v-if="!isCompany"
        :title="$t('inviteFriend')"
        @click="showReferralsDialog()"
      >
        <template #prepended-icon>
          <icon
            fixed-width
            :icon="['fal', 'heart']"
          />
        </template>
      </sidebar-item>
    </v-list>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { RouteLocation, RouteLocationRaw } from "vue-router";
import {
  InProgressPension,
  InProgressPensionStatus,
  PortfolioBase,
  PortfolioType
} from "@/clients";

import { UserAction } from "@/store/user/action-definitions";
import { UserMutation } from "@/store/user/mutations";
import { NavigationState } from "@/store/navigation/types";
import SidebarItem from "./sidebar-item.vue";

export default defineComponent({
  emits: ["click-signout"],
  components: {
    SidebarItem
  },
  data: () => ({
    InProgressPensionStatus
  }),
  computed: {
    ...mapGetters([
      "firstName",
      "unreadMessagesCount",
      "isCompany",
      "legalEntities",
      "pensionPortfolios",
      "isTestUser",
      "answeredNewEsg"
    ]),
    portfolios(): PortfolioBase[] {
      const portfolios = this.$store.state.navigationStore.portfolios as PortfolioBase[];
      if (portfolios) {
        return portfolios.filter((x) => x.portfolioType !== PortfolioType.OccupationalPension);
      }
      return [];
    },
    nameTextSmall(): boolean {
      return this.firstName ? this.firstName.length > 11 : false;
    },
    numberOfPortfolios(): number {
      const { portfolios } = this.$store.state.navigationStore as NavigationState;
      if (portfolios) {
        return portfolios.filter((x) => x.portfolioType !== PortfolioType.OccupationalPension)
          .length;
      }
      return 0;
    }
  },
  created(): void {
    this.$store.dispatch(UserAction.loadMessages);
    this.$store.dispatch(UserAction.loadBanner);
  },
  methods: {
    loadImageByName(name: string) {
      return new URL(`/assets/icons/${name}`, import.meta.url).href;
    },
    goToRoute(location: RouteLocationRaw): void {
      this.$router.push(location).catch((err) => {});
    },
    showReferralsDialog(): void {
      this.$store.commit(UserMutation.setShowReferralsDialog, true);
    },
    pensionInProgress(pensions: InProgressPension[]): boolean {
      return pensions.some((x) => this.$store.getters.pensionInProgress(x.status));
    },
    pensionRequiresSupplementaryInfo(pensions: InProgressPension[]): boolean {
      return pensions.some((x) => this.$store.getters.pensionRequiresSupplementaryInfo(x.status));
    }
  }
});
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: $nearly-black;
  border-radius: 0.5rem;
  box-shadow: 0 0.1875rem 1.25rem rgba(0, 0, 0, 0.2);
  overflow: hidden; // Fixes border radius at bottom not working sometimes
  min-height: 36rem;
  width: 13.75rem;
  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    :deep(path) {
      fill: white;
      opacity: 0.8;
    }
    @include large-up {
      width: 3.5rem;
      height: 3.5rem;
    }
  }
  &__button-icon {
    width: 1.2rem;
    height: 1.2rem;
    :deep(path) {
      fill: white;
      opacity: 1;
    }
  }
  &__button {
    background-color: transparent;
    &:hover {
      @include cursorHover {
        box-shadow: 0 0.1875rem 0.75rem #0000001c;
      }
    }
  }

  &__list {
    background-color: $nearly-black;
  }
  &__name {
    color: white;
    opacity: 0.8;
    font-family: $heading-font-family;
    margin: 0.5rem 1rem 0 1rem;
    word-wrap: break-word;
    text-align: center;
  }
  &__name--small {
    font-size: 0.75rem;
  }
  &__user {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__top-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 0rem 0.5rem;
    &--change-account {
      margin-right: auto;
    }
  }
  &__message-count-text {
    color: #fff;
    letter-spacing: 0.15rem;
  }
}
</style>
