import type { ActionTree } from "vuex";
import type { RootState } from "../../types";
import { UserMutation } from "../user/mutations";
import type { BankIdState } from "./types";
import { BankIdStatus, StartMode } from "./types";
import { BankIdMutation } from "./mutations";
import { baseUrl } from "@/clients/config";
import type {
  AuthenticateStartResponse,
  LoginResponse,
  SignStartResponse,
} from "@/clients";
import {
  AssentlyCompleteRequest,
  AuthenticationStatus,
  BankIdBackendStatus,
  BankIdClient,
  CancelAuthRequest,
  CancelSignRequest,
  CollectLoginRequest,
  CollectSignRequest,
  LoginRequest,
  LoginStatus,
} from "@/clients";
import { isChrome, isFirefox, isHandheld, isIOS, isInstagramOrFacebookIOS } from "@/clients/bowser";
import { constants } from "@/config/general";
import { createPollingPromise as createGenericPollingPromise } from "@/utils/polling-promise";
import { createBankIdRedirectParameterValue } from "@/utils/mobile-redirect";

const bankIdClient = new BankIdClient(baseUrl);

const autoStartURLPrefixIOS = "https://app.bankid.com?autostarttoken=";
const autoStartURLPrefixDesktop = "bankid:///?autostarttoken=";
const autoStartURLPrefix = "bankid:///?autostarttoken=";

export const BankIdAction = {
  authStart: "authStart",
  signStart: "signStart",
  collectAndLogin: "collectAndLogin",
  collectAndSign: "collectAndSign",
  cancelAuth: "cancelAuth",
  cancelSign: "cancelSign",
  authenticateAndLogin: "authenticateAndLogin",
  norwayLoginStart: "norwayLoginStart",
  norwayLoginComplete: "norwayLoginComplete",
  norwaySignStart: "norwaySignStart",
  sign: "sign",
  refreshQrCode: "refreshQrCode",
};

class AbortedError {}

async function createPollingPromise(
  state: BankIdState | null,
  collectFunc: () => Promise<any>,
  totalTimeWaited: number = 0,
  retryCount: number = 0,
): Promise<any> {
  const wait = (seconds: number) =>
    new Promise(resolve => setTimeout(() => resolve(true), seconds * 1000));

  if (state && state.status === BankIdStatus.CANCELED) {
    return new Promise<any>((_, reject) => reject(new AbortedError()));
  }
  try {
    const result = await collectFunc();
    if (result && result.status !== undefined) {
      if (result.status === BankIdBackendStatus.Completed) {
        return result;
      }
      // Branches that do the same thing left here to signify what can happen.
      if (totalTimeWaited > constants.bankIdPollingDelay * 60 * 2) {
        throw result;
      } else if (
        result.status === BankIdBackendStatus.Failed
        || result.status === "expiredTransaction"
      ) {
        throw result;
      } else if (result.status === BankIdBackendStatus.Cancelled) {
        throw result;
      }
    } else {
      throw new Error();
    }
    await wait(2);
    return createPollingPromise(state, collectFunc, totalTimeWaited + 2000, 0);
  } catch (e: any) {
    if (e instanceof AbortedError) {
      throw e;
    }
    if (e.status === BankIdBackendStatus.Failed || e.status === BankIdBackendStatus.Cancelled)
      throw e;

    // Retry count of three is arbitrarily chosen. Should start working in three tries if the cause is a broken HTTP connection.
    if (retryCount !== undefined && retryCount > 3)
      throw e;
    // If we return to browser at a bad time (not sure when this is), collect sometimes fails because it loses the
    // HTTP connection. In such cases, we want to try again.
    return createPollingPromise(state, collectFunc, totalTimeWaited + 2000, retryCount + 1);
  }
}

export function createAutoStartURL(autoStartToken: string | undefined): string | undefined {
  if (autoStartToken) {
    const autoStartURLSuffix = `&redirect=${createBankIdRedirectParameterValue()}`;
    let autoStartPrefix = "";
    if (isInstagramOrFacebookIOS() || isIOS()) {
      autoStartPrefix = autoStartURLPrefixIOS;
    } else if (isHandheld()) {
      autoStartPrefix = autoStartURLPrefix;
    } else {
      autoStartPrefix = autoStartURLPrefixDesktop;
    }
    return autoStartPrefix + autoStartToken + autoStartURLSuffix;
  }
  return undefined;
}

export const actions: ActionTree<BankIdState, RootState> = {
  async [BankIdAction.signStart]({ commit, state }): Promise<void> {
    commit(BankIdMutation.setStatus, BankIdStatus.PENDING);
    commit(BankIdMutation.setQrCode, undefined);
    commit(BankIdMutation.setAutoStartURL, undefined);
    commit(BankIdMutation.setAutoStartToken, undefined);

    return bankIdClient
      .signBankIdProcess(state.transactionId as string)

      .then((response: SignStartResponse | null) => {
        if (response) {
          // When the user cancels in our web they can do it before we have received a response here.
          // But we can't send a cancel to bankid until we have an orderRef so we do it
          // from here as soon as we get response instead of from the cancel action
          if (state.status === BankIdStatus.CANCELED) {
            bankIdClient.cancelSign(
              new CancelSignRequest({ transactionId: response.transactionId }),
            );
          } else {
            commit(BankIdMutation.setTransactionId, response.transactionId);
            commit(BankIdMutation.setAutoStartURL, createAutoStartURL(response.autoStartToken));
            commit(BankIdMutation.setAutoStartToken, response.autoStartToken);
          }
        }
      })
      .catch((e) => {
        commit(BankIdMutation.setStatus, BankIdStatus.FAILED);
      });
  },
  async [BankIdAction.collectAndSign]({ state, commit }): Promise<void> {
    const collectSignRequest = new CollectSignRequest({
      transactionId: state.transactionId,
    });

    return createPollingPromise(state, () => bankIdClient.collect(collectSignRequest))
      .then((response) => {
        commit(BankIdMutation.setStatus, BankIdStatus.COMPLETE);
      })
      .catch((response) => {
        if (response) {
          // We can end up polling more than one BankID session at once for one iteration
          // so to avoid failing the latest one because a previous one failed we check that orderRefs match
          if (response.transactionId === state.transactionId) {
            if (response.status === BankIdBackendStatus.Cancelled) {
              commit(BankIdMutation.setStatus, BankIdStatus.CANCELED);
              return;
            } else if (response.status === BankIdBackendStatus.Failed) {
              commit(BankIdMutation.setStatus, BankIdStatus.FAILED);
              return;
            } else if (state.status === BankIdStatus.PENDING) {
              commit(BankIdMutation.setStatus, BankIdStatus.FAILED);
            }
          }
        } else if (state.status === BankIdStatus.PENDING) {
          commit(BankIdMutation.setStatus, BankIdStatus.FAILED);
        }
        throw response;
      });
  },
  async [BankIdAction.authStart](
    { commit, state },
    { personalIdentityNumber, startMode },
  ): Promise<void> {
    commit(UserMutation.setSafeToReloadApp, false);
    commit(BankIdMutation.resetBankId);
    commit(BankIdMutation.setStatus, BankIdStatus.PENDING);
    commit(BankIdMutation.setStartMode, startMode);
    const request = new LoginRequest({ personalIdentityNumber });
    return bankIdClient
      .authenticateStart(request)
      .then((response: AuthenticateStartResponse | null) => {
        if (response && response.status === AuthenticationStatus.Success) {
          // When the user cancels in our web they can do it before we have received a response here.
          // But we can't send a cancel to bankid until we have an orderRef so we do it
          // from here as soon as we get response instead of from the cancel action
          if (state.status === BankIdStatus.CANCELED) {
            bankIdClient.cancelAuth(
              new CancelAuthRequest({ transactionId: response.transactionId }),
            );
          } else {
            commit(BankIdMutation.setTransactionId, response.transactionId);
            commit(BankIdMutation.setAutoStartURL, createAutoStartURL(response.autoStartToken));
            commit(BankIdMutation.setAutoStartToken, response.autoStartToken);
            commit(BankIdMutation.setQrCode, response.qrCode);
          }
        } else {
          commit(
            BankIdMutation.setAuthenticationStatus,
            response ? response.status : AuthenticationStatus.UnknownError,
          );
          throw new Error("Failed to authenticate");
        }
      });
  },
  async [BankIdAction.refreshQrCode]({ commit, state }): Promise<void> {
    try {
      const response = await bankIdClient.refreshQrCode(state.transactionId as string);
      commit(BankIdMutation.setQrCode, response?.qrCode);
    } catch (error: any) {
      // TODO: Handle for sign too
      commit(BankIdMutation.setAuthenticationStatus, AuthenticationStatus.UnknownError);
      throw new Error(`Failed to refresh qr code ${error}`);
    }
  },
  async [BankIdAction.collectAndLogin](
    { state, commit },
    isSignup: boolean,
  ): Promise<LoginResponse | undefined> {
    if (state.transactionId) {
      const collectRequest = new CollectLoginRequest({
        transactionId: state.transactionId,
        isSignup,
      });

      return createPollingPromise(state, () => {
        if (state.transactionId !== collectRequest.transactionId) {
          throw new AbortedError();
        }

        return bankIdClient.collectAndLogin(collectRequest);
      })
        .then(async (response) => {
          if (response.loginResponse !== undefined) {
            if (
              response.loginResponse.token
              && response.loginResponse.status === LoginStatus.Success
            ) {
              commit(UserMutation.setToken, response.loginResponse.token);
            } else {
              commit(UserMutation.setLoginErrorStatus, response.loginResponse.status);
            }
          }

          commit(BankIdMutation.setStatus, BankIdStatus.COMPLETE);

          return Promise.resolve(response.loginResponse);
        })
        .catch((response) => {
          if (response instanceof AbortedError) {
            return Promise.resolve();
          }

          if (response) {
            // We can end up polling more than one BankID session at once for one iteration
            // so to avoid failing the latest one because a previous one failed we check that transactionIds match
            if (
              response.transactionId === state.transactionId
              && state.status === BankIdStatus.PENDING
            ) {
              commit(BankIdMutation.setStatus, BankIdStatus.FAILED);
            }
          } else if (state.status === BankIdStatus.PENDING) {
            commit(BankIdMutation.setStatus, BankIdStatus.FAILED);
          }

          return Promise.reject();
        });
    }
    return Promise.resolve(undefined);
  },
  async [BankIdAction.cancelAuth]({ state, commit }): Promise<void> {
    const { transactionId, status } = state;
    if (status === BankIdStatus.PENDING) {
      commit(BankIdMutation.setStatus, BankIdStatus.CANCELED);
      if (transactionId) {
        await bankIdClient.cancelAuth(new CancelAuthRequest({ transactionId }));
      }
    }
  },
  async [BankIdAction.cancelSign]({ state, commit }): Promise<void> {
    const { transactionId, status } = state;
    if (transactionId && status === BankIdStatus.PENDING) {
      await bankIdClient.cancelSign(new CancelSignRequest({ transactionId }));
    }
  },
  async [BankIdAction.authenticateAndLogin](
    { state, dispatch },
    authParameters: {
      personalIdentityNumber: string;
      startMode: StartMode;
      isSignup: boolean;
    },
  ): Promise<LoginResponse | undefined> {
    await dispatch(BankIdAction.authStart, authParameters);

    if (authParameters.startMode === StartMode.QR_CODE) {
      createGenericPollingPromise(
        async () => {
          if (state.status !== BankIdStatus.PENDING) {
            // Stop polling
            return Promise.resolve(true);
          }

          try {
            await dispatch(BankIdAction.refreshQrCode);
          } catch (error: any) {
            console.error("failed to refresh qr code");
            return Promise.reject(error);
          }

          // Continue polling
          return Promise.resolve(false);
        },
        import.meta.env.VITE_ENV === "production" ? 1000 : 5000,
      );
    }

    return dispatch(BankIdAction.collectAndLogin, authParameters.isSignup);
  },
  async [BankIdAction.sign]({ dispatch, rootState, state }): Promise<void> {
    if (rootState.userStore.locale !== "no") {
      await dispatch(BankIdAction.signStart);
      if (!isHandheld()) {
        createGenericPollingPromise(
          () => {
            if (state.status === BankIdStatus.PENDING) {
              try {
                dispatch(BankIdAction.refreshQrCode);
              } catch (error: any) {
                console.error("failed to refresh qr code");
                return Promise.reject();
              }
              return Promise.resolve(false);
            } else {
              return Promise.resolve(true);
            }
          },
          import.meta.env.VITE_ENV === "production" ? 1000 : 5000,
        );
      }
      await dispatch(BankIdAction.collectAndSign);
    }
  },
  async [BankIdAction.norwaySignStart](
    { dispatch, commit, state },
    returnPath: string,
  ): Promise<string | undefined> {
    if (!state.transactionId) {
      throw new Error("No transactionId in state");
    }
    const response = await bankIdClient.norwaySignStart(state.transactionId, returnPath);
    commit(BankIdMutation.setAssentlyToken, response?.assentlyToken);
    commit(BankIdMutation.setTransactionId, response?.transactionId);
    commit(BankIdMutation.setSignMessage, response?.signMessage);
    commit(BankIdMutation.setSignTitle, response?.signTitle);
    return response?.assentlyToken;
  },
  async [BankIdAction.norwayLoginStart](
    { dispatch, commit },
    code: string,
  ): Promise<string | undefined> {
    const response = await bankIdClient.norwayLoginStart();
    commit(BankIdMutation.setAssentlyToken, response?.assentlyToken);
    commit(BankIdMutation.setTransactionId, response?.transactionId);

    return response?.assentlyToken;
  },
  async [BankIdAction.norwayLoginComplete](
    { dispatch, commit, state },
    parameters: { identityToken: string; isSignup: boolean },
  ): Promise<LoginResponse | null> {
    const loginResponse = await bankIdClient.norwayLoginComplete(
      new AssentlyCompleteRequest({
        transactionId: state.transactionId,
        identityToken: parameters.identityToken,
        isSignup: parameters.isSignup,
      }),
    );
    if (loginResponse) {
      if (loginResponse && loginResponse.token && loginResponse.status === LoginStatus.Success) {
        commit(UserMutation.setToken, loginResponse.token);
      } else {
        commit(UserMutation.setLoginErrorStatus, loginResponse.status);
      }
    }
    return loginResponse;
  },
};
