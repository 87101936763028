<template>
  <div class="signup">
    <SignupHeaderTransition
      name="fade-header"
      mode="out-in"
    >
      <signup-header
        v-if="isFirstQuestion"
        :hide-buttons="hideButtons"
        :has-started-signup="hasStartedSignup"
        progress-heading-key="newSignup"
        :exit-dialog-text="$t('exitDialogSignupBody')"
        @exit="exit"
      />
    </SignupHeaderTransition>
    <router-view v-slot="{ Component, route }">
      <SignupTransition
        name="fade"
        mode="out-in"
      >
        <component
          :is="Component"
          :key="route.fullPath"
        />
      </SignupTransition>
    </router-view>
    <loading-spinner
      v-if="loadingAffiliateCodeVerification"
      center
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { questionPaths, additionalQuestions, CURRENT_SIGNUP_PATH_KEY } from "@/config/signup";
import { getSignUpInitialState } from "@/store/signup";
import { SignupMutation } from "@/store/signup/mutations";

import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { trackPage, trackEventOnlyGa4, trackEvent } from "@/clients/segment";
import { baseUrl } from "@/clients/config";

import {
  SIGNUP_AFFILIATE_REFFERAL_CODE,
  SIGNUP_AFFILIATE_REFFERAL_NAME,
  SIGNUP_REFERRAL
} from "@/config/general";
import { UserAction } from "@/store/user/action-definitions";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { ApplicationUsersClient, LogLevel, TiedAgentsClient } from "@/clients";
import log from "@/clients/log";

import { validateAffiliateCode } from "@/clients/services";

import SignupHeader from "@/components/header.vue";
import { SignupFlowModificationType } from "@/config/signup-modification";
import { UserMutation } from "@/store/user/mutations";
import { SignupNavigationMutation } from "@/store/signup-navigation/mutations";
import SignupHeaderTransition from "../common-signup/signup-header-transition.vue";
import SignupTransition from "../common-signup/signup-transition.vue";
const usersClient = new ApplicationUsersClient(baseUrl);
const tiedAgentClient = new TiedAgentsClient(baseUrl);

const logger = "signup.vue";

export default defineComponent({
  head() {
    return {
      title: this.$t("signup")
    };
  },
  components: {
    SignupHeader,
    LoadingSpinner,
    SignupHeaderTransition,
    SignupTransition
  },
  data: () => ({
    latestVisitedPath: null as null | string
  }),
  computed: {
    hasStartedSignup(): boolean {
      const { email } = this.$store.state.signupStore.contactDetails;
      return !!email;
    },
    hideButtons(): boolean {
      return this.hasCompletedSignup;
    },
    isFirstQuestion(): boolean {
      return !this.$store.state.signupNavigationStore.questionProgress;
    },
    hasCompletedSignup(): boolean {
      return this.$store.state.signupStore.completedSignUp;
    },
    loadingAffiliateCodeVerification(): boolean {
      return this.$store.state.signupStore.loadingAffiliateCodeVerification;
    }
  },
  created(): void {
    window.addEventListener("beforeunload", this.trackClosedTab);
    this.latestVisitedPath = sessionStorage.getItem(CURRENT_SIGNUP_PATH_KEY);
    this.$store.commit(UserMutation.setToken, undefined);
  },
  // When the user has reached the end they shouldn't be able to go back;
  async mounted() {
    // This check is to reset the signup state if the user has already created an account with the stored information.
    // This resets the form so the user can create another account
    window.onpopstate = (event: PopStateEvent) => {
      this.latestVisitedPath = sessionStorage.getItem(CURRENT_SIGNUP_PATH_KEY);
      if (this.hasCompletedSignup) {
        // TODO Erik: push them to the portfolio overview or similar
        this.$router.push("/signup/account/congratulations");
      }
    };

    // Track signup visit.
    trackPage("Signup");

    const { referral } = { referral: "" }; // this.$route.query;
    const affiliateCode = undefined; //this.$route.query.affiliate_code;
    const affiliateName = undefined; //this.$route.query.affiliate_name;
    const { tiedAgent } = this.$route.query;

    // If the path is /signup we are going to the first page, so verify and store referral stuff
    if (this.$route.path.endsWith("/signup")) {
      this.$store.commit(SignupMutation.setState, getSignUpInitialState());
      await this.$store.dispatch(SignupNavigationAction.initializeSignup, {
        questionPaths: questionPaths.map((x) => x.paths),
        currentPathKey: CURRENT_SIGNUP_PATH_KEY,
        additionalQuestionPaths: additionalQuestions
      });
      this.$store.commit(
        SignupNavigationMutation.setProgressHeadings,
        questionPaths.map((x) => x.header)
      );

      if (tiedAgent) {
        const tiedAgentInfo = await tiedAgentClient.getTiedAgent(tiedAgent as string);
        this.$store.commit(UserMutation.setTiedAgent, tiedAgentInfo);
        this.$store.commit(UserMutation.setPrimaryColor, tiedAgentInfo?.color as string);
      }

      if (affiliateCode && affiliateName) {
        this.clearReferralAndAffiliateData();
        this.setAffiliateReferralInfo(affiliateCode as string, affiliateName as string);
        const valid = await validateAffiliateCode(
          affiliateCode as string,
          affiliateName as string,
          true
        );
        if (!valid) {
          log(
            logger,
            `Invalid affiliate link used (code=${affiliateCode};name=${affiliateName})`,
            LogLevel.Information
          );
          this.clearReferralAndAffiliateData();
          this.$router.push({ path: "invalid-referral" });
        } else {
          if (affiliateName === "stepler") {
            this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
              modification: SignupFlowModificationType.SteplerIntro,
              include: true
            });
          } else if (valid && affiliateName === "leads") {
            this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
              modification: SignupFlowModificationType.LeadsIntro,
              include: true
            });
          } else if (valid && affiliateName === "familjekortet") {
            this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
              modification: SignupFlowModificationType.FamiljekortetIntro,
              include: true
            });
          }
          this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
        }
      } else if (referral) {
        this.clearReferralAndAffiliateData();
        this.$store.commit(SignupMutation.setReferralCode, referral as string);
        sessionStorage.setItem(SIGNUP_REFERRAL, referral as string);
        const valid = await this.verifyReferralCode(referral as string);
        if (valid) {
          await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
            modification: SignupFlowModificationType.ReferralIntro,
            include: true
          });
        }
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      } else {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    } // If not signup we might be refreshing or have been redirected from Tink
    else {
      const cachedAffiliateReferralCode = sessionStorage.getItem(SIGNUP_AFFILIATE_REFFERAL_CODE);
      const cachedAffiliateReferralName = sessionStorage.getItem(SIGNUP_AFFILIATE_REFFERAL_NAME);
      const cachedReferral = sessionStorage.getItem(SIGNUP_REFERRAL);
      if (cachedAffiliateReferralCode || cachedAffiliateReferralName) {
        this.setAffiliateReferralInfo(
          cachedAffiliateReferralCode as string,
          cachedAffiliateReferralName as string
        );
      } else if (cachedReferral) {
        this.$store.commit(SignupMutation.setReferralCode, cachedReferral);
        sessionStorage.setItem(SIGNUP_REFERRAL, referral as string);
        this.verifyReferralCode(cachedReferral);
      }
    }
  },
  methods: {
    exit() {
      this.$store.commit(UserMutation.setToken, undefined);

      const { path } = this.$router.currentRoute.value;
      trackEvent("closed_signup", {
        from: path
      });
      this.$router.push({ name: "login" });
      this.$store.commit(SignupMutation.setState, getSignUpInitialState());
    },
    clearReferralAndAffiliateData() {
      sessionStorage.removeItem(SIGNUP_REFERRAL);
      this.$store.commit(SignupMutation.setReferralCode, undefined);

      this.$store.commit(SignupMutation.setAffiliateReferralCode, undefined);
      this.$store.commit(SignupMutation.setAffiliateReferralName, undefined);
      sessionStorage.removeItem(SIGNUP_AFFILIATE_REFFERAL_CODE);
      sessionStorage.removeItem(SIGNUP_AFFILIATE_REFFERAL_NAME);
    },
    setAffiliateReferralInfo(code: string, name: string) {
      this.$store.commit(SignupMutation.setAffiliateReferralCode, code);
      this.$store.commit(SignupMutation.setAffiliateReferralName, name);
      sessionStorage.setItem(SIGNUP_AFFILIATE_REFFERAL_CODE, code as string);
      sessionStorage.setItem(SIGNUP_AFFILIATE_REFFERAL_NAME, name as string);
    },
    trackClosedTab() {
      if (!navigator.sendBeacon) return;

      // Without this check we will detect moving to another part of the signup as closing the tab.
      const { path } = this.$router.currentRoute.value;
      if (!path.includes("signup")) return;

      const { contactDetails } = this.$store.state.signupStore;
      if (!contactDetails) {
        // If we don't have any contact info yet we can't track in segment since the first point we track
        // is when we get a tracking guid from lead creation
        trackEventOnlyGa4("closed_signup", { method: "tab", page: path });
        return;
      }

      const url = `${baseUrl}/api/Analytics`;
      // We need to send this as text/plain because apparently Chrome doesn't accept
      // beacon requests as json
      const blob = new Blob(
        [
          JSON.stringify({
            trackingGuid: this.$store.state.signupStore.trackingGuid,
            eventName: "closed_signup"
          })
        ],
        { type: "text/plain; charset=UTF-8" }
      );
      // We track to "Analytics" (i.e. Segment) and GA4 separately because Segment currently cannot pass information
      // to GA4 nor can it receive from GA4. The closed signup event is needed in Customer IO to send a drop-off email,
      // which is Segment's responsibility to signal.
      navigator.sendBeacon(url, blob);
      trackEventOnlyGa4("closed_signup", { method: "tab", page: path });
    },
    async verifyReferralCode(referralCode: string): Promise<boolean> {
      const valid = await usersClient.validReferralCode(referralCode);
      if (valid) {
        this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("validReferral"));
      } else {
        this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("invalidReferral"));
      }
      return valid;
    }
  }
});
</script>

<style lang="scss">
.signup {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  height: 100%;
  @include medium-up {
    padding: 0 6rem;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-header-enter-active {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    transition: all 0.2s;
  }
  .fade-header-leave-active {
    transition: all 0.2s;
  }
  .fade-header-enter-from,
  .fade-header-leave-to {
    opacity: 0;
  }
}
</style>
