<template>
  <div class="bankid">
    <template v-if="isLogin">
      <template v-if="autoStartToken">
        <div
          v-if="startMode === StartMode.QR_CODE"
          class="bankid__qr-wrapper"
        >
          <span>{{
            loadingCurrentUser || completed ? "" : $t("components.bank-id-se.qrCodeText")
          }}</span>
          <QrcodeVue
            v-if="!loadingCurrentUser && !completed"
            style="align-self: center; margin-top: 1rem"
            :value="qrCode"
            :size="148"
            level="H"
          />
          <div v-else>
            <div class="bankid__icon-wrapper">
              <LoadingSpinner />
            </div>
          </div>
        </div>
        <div v-else>
          <!-- We don't support signing with BankID on file, so just check this on login -->
          <span
            v-if="!loadingCurrentUser && !completed"
            class="bankid__link"
          >{{
            !isCompanySignupLogin && isLogin && !isHandheld
              ? $t("components.bank-id-se.identifyInDesktop")
              : $t("components.bank-id-se.identifyInApp")
          }}</span>
          <div class="bankid__icon-wrapper">
            <BankIdIcon :size="9" />
          </div>
        </div>
      </template>
      <template v-else>
        <span class="bankid__wait-text">
          {{ $t("waitBankIdMobile") }}
        </span>
        <LoadingSpinner />
      </template>
    </template>
    <!-- Sign -->
    <template v-else>
      <template v-if="isHandheld">
        <p
          v-if="!withoutHeading"
          class="bankid__header"
        >
          {{ $t("signBankId") }}
        </p>
        <a
          class="bankid__link"
          :href="autoStartURL"
        >{{ autoStartURL ? $t("components.bank-id-se.openBankId") : $t("waitBankIdMobile") }}</a>
        <div class="bankid__icon-wrapper">
          <BankIdIcon :size="9" />
        </div>
      </template>
      <template v-else-if="qrCode">
        <p
          v-if="!withoutHeading"
          class="bankid__header"
        >
          {{ $t("signBankId") }}
        </p>
        <span style="text-align: center">{{ $t("components.bank-id-se.qrCodeText") }}</span>
        <QrcodeVue
          v-if="!completed && qrCode"
          style="align-self: center; margin-top: 1rem"
          :value="qrCode"
          :size="148"
          level="L"
        />
        <a
          class="bankid__link"
          :href="autoStartURL"
        >{{ autoStartURL ? $t("components.bank-id-se.openBankIdDesktop") : $t("waitBankIdMobile") }}</a>
      </template>
      <template v-else>
        <LoadingSpinner />
      </template>
    </template>
    <a
      class="bankid__link"
      @click="cancel"
    >{{ isLogin ? $t("cancelLogin") : $t("components.bank-id-se.cancelSign") }}</a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import QrcodeVue from "qrcode.vue";
import BankIdIcon from "@/components/bank-id-icon.vue";
import { BankIdAction } from "@/store/bankid/actions";
import type { BankIdState } from "@/store/bankid/types";
import { BankIdStatus, StartMode } from "@/store/bankid/types";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { isHandheld } from "@/clients/bowser";
import { BankIdMutation } from "@/store/bankid/mutations";

export default defineComponent({
  components: {
    BankIdIcon,
    QrcodeVue,
    LoadingSpinner,
  },
  props: {
    isCompanySignupLogin: {
      type: Boolean,
      default: undefined,
    },
    isLogin: {
      type: Boolean,
      default: undefined,
    },
    withoutHeading: {
      type: Boolean,
      default: undefined,
    },
  },
  emits: ["complete", "canceled", "failed"],
  data: () => ({
    StartMode,
    isHandheld: isHandheld(),
    completed: false,
  }),
  computed: {
    startMode(): StartMode | undefined {
      return this.$store.state.bankIdStore.startMode;
    },
    autoStartURL(): string {
      return this.$store.state.bankIdStore.autoStartURL;
    },
    bankIdStatus(): BankIdStatus {
      return this.$store.state.bankIdStore.status;
    },
    autoStartToken(): string | undefined {
      return this.$store.state.bankIdStore.autoStartToken;
    },
    qrCode(): string | undefined {
      const { qrCode } = this.$store.state.bankIdStore as BankIdState;
      return qrCode;
    },
    loadingCurrentUser(): boolean {
      return this.isLogin && this.$store.state.userStore.loadingCurrentUser;
    },
  },
  watch: {
    bankIdStatus: {
      immediate: true,
      async handler(newStatus, oldStatus) {
        if (oldStatus === BankIdStatus.PENDING) {
          if (newStatus === BankIdStatus.COMPLETE) {
            this.completed = true;
            this.$emit("complete");
          } else if (newStatus === BankIdStatus.CANCELED) {
            if (this.isLogin) {
              await this.$store.dispatch(BankIdAction.cancelAuth);
            } else {
              await this.$store.dispatch(BankIdAction.cancelSign);
            }
            this.completed = false;
            this.$emit("canceled");
          } else {
            this.$emit("failed");
          }
        }
      },
    },
    autoStartURL(newValue) {
      if (newValue && this.isLogin && this.startMode === StartMode.AUTOSTART_TOKEN) {
        this.openBankId();
      }
    },
  },
  methods: {
    openBankId(): void {
      window.location.href = this.autoStartURL;
    },
    cancel(): void {
      this.$store.commit(BankIdMutation.setStatus, BankIdStatus.CANCELED);
    },
  },
});
</script>

<style lang="scss" scoped>
.bankid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 3rem;
  height: 100%;
  align-items: center;

  &__header {
    font-family: $heading-font-family;
    font-size: 1.125rem;
    font-weight: 500;
    color: $nearly-black;
    margin-bottom: 1rem;
    text-align: center;
  }
  &__icon-wrapper {
    display: flex;
    justify-content: center;
    margin: 1rem auto;
    max-width: 100%;
    width: 21.75rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  &__link {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    cursor: pointer;
  }
  &__qr-wrapper {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 28rem;
  }
  &__qr-code {
    padding-top: 2rem;
    justify-content: center;
    @include small-up {
      padding-bottom: 4rem;
    }
  }
  &__wait-text {
    padding-top: 2rem;
  }
}
</style>
