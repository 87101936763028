<template>
  <div class="signup">
    <SignupHeaderTransition>
      <SignupHeader
        v-if="!isFirstQuestion"
        :hide-buttons="hideButtons"
        :has-started-signup="hasStartedSignup"
        :exit-dialog-text="$t('create-portfolio.create-portfolio.exitDialogSignupBody')"
        @exit="exit"
      />
    </SignupHeaderTransition>
    <router-view
      v-if="initialized"
      v-slot="{ Component, route }"
    >
      <SignupTransition>
        <component
          :is="Component"
          :key="route.fullPath"
        />
      </SignupTransition>
    </router-view>
    <LoadingSpinner
      v-else
      style="margin: auto"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SignupHeaderTransition from "../common-signup/signup-header-transition.vue";
import SignupTransition from "../common-signup/signup-transition.vue";
import { ApplicationUsersClient, type LegalEntityUser } from "@/clients";
import { baseUrl } from "@/clients/config";
import { trackEvent, trackEventOnlyGa4 } from "@/clients/segment";
import SignupHeader from "@/components/header.vue";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import {
  CURRENT_SIGNUP_PATH_KEY_NEW,
  additionalQuestions,
  firstPortfolioQuestionPaths,
  firstPortfolioTiedAgentQuestionPaths,
  newPortfolioQuestionPathsSameAccount,
  newPortfolioTiedAgentQuestionPathsSameAccount,
} from "@/config/create-portfolio";
import { CURRENT_LEGAL_ENTITY } from "@/config/general";
import { NavigationAction } from "@/store/navigation/actions";
import { getSignUpInitialState } from "@/store/signup";
import { SignupAction } from "@/store/signup/actions";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupNavigationMutation } from "@/store/signup-navigation/mutations";
import { UserAction } from "@/store/user/action-definitions";
import { UserMutation } from "@/store/user/mutations";
import type { SignupSectionPaths } from "@/types/signup";
import { reloadIfWrongLocale } from "@/utils/locale-url-helper";

const usersClient = new ApplicationUsersClient(baseUrl);

export default defineComponent({
  components: {
    SignupHeader,
    LoadingSpinner,
    SignupTransition,
    SignupHeaderTransition,
  },
  data: () => ({
    latestVisitedPath: null as null | string,
    initialized: false,
    progressHeadings: undefined as string[] | undefined,
  }),
  head() {
    return {
      title: this.$t("create-portfolio.create-portfolio.newPortfolio"),
    };
  },
  computed: {
    tiedAgent() {
      return this.$store.state.userStore.currentLegalEntity?.tiedAgent;
    },
    hasStartedSignup(): boolean {
      const { email } = this.$store.state.signupStore.contactDetails;
      return !!email;
    },
    hideButtons(): boolean {
      return this.hasCompletedSignup;
    },
    isFirstQuestion(): boolean {
      return !this.$store.state.signupNavigationStore.questionProgress;
    },
    hasCompletedSignup(): boolean {
      return this.$store.state.signupStore.completedSignUp;
    },
    loadingAffiliateCodeVerification(): boolean {
      return this.$store.state.signupStore.loadingAffiliateCodeVerification;
    },
  },
  created(): void {
    window.addEventListener("beforeunload", this.trackClosedTab);
    this.latestVisitedPath = sessionStorage.getItem(CURRENT_SIGNUP_PATH_KEY_NEW);
  },
  // When the user has reached the end they shouldn't be able to go back;
  async mounted() {
    this.initialized = false;

    // This check is to reset the signup state if the user has already created an account with the stored information.
    // This resets the form so the user can create another account
    window.onpopstate = () => {
      this.latestVisitedPath = sessionStorage.getItem(CURRENT_SIGNUP_PATH_KEY_NEW);
      if (this.hasCompletedSignup) {
        // TODO Erik: push them to the portfolio overview or similar
        this.$router.push("/create-portfolio/account/congratulations");
      }
    };

    await this.$store.dispatch(UserAction.loadCurrentUser);
    if (!this.$store.state.userStore.currentLegalEntity) {
      const legalEntityBrickId = sessionStorage.getItem(CURRENT_LEGAL_ENTITY);
      const legalEntityUsers = this.$store.state.userStore.currentUser
        .legalEntityUsers as LegalEntityUser[];
      if (legalEntityBrickId && this.$store.state.userStore.currentUser) {
        this.$store.commit(
          UserMutation.setLegalEntity,
          legalEntityUsers.find(
            (leu: LegalEntityUser) => leu.legalEntity?.brickId === legalEntityBrickId,
          )?.legalEntity,
        );
      }
      if (this.$store.state.userStore.currentLegalEntity) {
        reloadIfWrongLocale(
          this.$route.params.locale as string,
          this.$route.fullPath,
          this.$store.state.userStore.currentLegalEntity.countryCode,
        );
      }
    }

    if (!this.$store.state.navigationStore.portfolios) {
      await this.$store.dispatch(NavigationAction.loadPortfolios);
    }

    const hasPortfolios = this.$store.state.navigationStore.portfolios.length > 0;
    // If the path is /signup we are going to the first page, so verify and store referral stuff
    if (this.$route.path.endsWith("/create-portfolio")) {
      this.$store.commit(SignupMutation.setState, getSignUpInitialState());

      if (this.tiedAgent) {
        this.$store.commit(SignupMutation.setTiedAgent, this.tiedAgent);
        this.$store.commit(SignupMutation.setPrimaryColor, this.tiedAgent?.color as string);
      }

      let questionPaths: SignupSectionPaths[];
      if (hasPortfolios) {
        await this.$store.dispatch(SignupAction.loadBankAccountAlternatives);

        questionPaths = this.tiedAgent
          ? newPortfolioTiedAgentQuestionPathsSameAccount
          : newPortfolioQuestionPathsSameAccount;
      } else {
        questionPaths = this.tiedAgent
          ? firstPortfolioTiedAgentQuestionPaths
          : firstPortfolioQuestionPaths;
      }
      this.$store.dispatch(SignupNavigationAction.initializeSignup, {
        questionPaths: questionPaths.map(x => x.paths),
        currentPathKey: CURRENT_SIGNUP_PATH_KEY_NEW,
        additionalQuestionPaths: additionalQuestions,
      });
      this.$store.commit(
        SignupNavigationMutation.setProgressHeadings,
        questionPaths.map(x => x.header),
      );

      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }

    if (this.$store.state.userStore.currentLegalEntity && hasPortfolios) {
      this.$store.commit(
        SignupMutation.setMonthlyIncome,
        this.$store.state.userStore.currentLegalEntity.monthlySalary,
      );
      this.$store.commit(
        SignupMutation.setMonthlyRemainder,
        this.$store.state.userStore.currentLegalEntity.monthlyRemaining,
      );
      this.$store.commit(
        SignupMutation.setPersonalIdentityNumber,
        this.$store.state.userStore.currentUser.personalIdentityNumber,
      );
    }

    this.$store.commit(
      SignupMutation.setPersonalIdentityNumber,
      this.$store.state.userStore.currentUser.personalIdentityNumber,
    );

    this.initialized = true;
  },
  methods: {
    async exit(): Promise<void> {
      const { path } = this.$router.currentRoute.value;
      trackEvent("closed_new_portfolio_signup", {
        from: path,
      });
      const { firstPortfolio } = this.$store.getters;
      if (firstPortfolio) {
        await this.$router.push({ name: "portfolio", params: { id: firstPortfolio.id } });
      } else {
        await this.$router.push({ name: "overview" });
      }
      this.$store.commit(SignupMutation.setState, getSignUpInitialState());
    },
    trackClosedTab() {
      if (!navigator.sendBeacon) {
        return;
      }

      // Without this check we will detect moving to another part of the signup as closing the tab.
      const { path } = this.$router.currentRoute.value;
      if (!path.includes("create-portfolio")) {
        return;
      }

      const url = `${baseUrl}/api/Analytics`;
      // We need to send this as text/plain because apparently Chrome doesn't accept
      // beacon requests as json
      const blob = new Blob(
        [
          JSON.stringify({
            trackingGuid: this.$store.state.signupStore.trackingGuid,
            eventName: "closed_new_portfolio_signup",
          }),
        ],
        { type: "text/plain; charset=UTF-8" },
      );
      // We track to "Analytics" (i.e. Segment) and GA4 separately because Segment currently cannot pass information
      // to GA4 nor can it receive from GA4. The closed signup event is needed in Customer IO to send a drop-off email,
      // which is Segment's responsibility to signal.
      navigator.sendBeacon(url, blob);
      trackEventOnlyGa4("closed_new_portfolio_signup", { method: "tab", page: path });
    },
    async verifyReferralCode(referralCode: string): Promise<boolean> {
      const valid = await usersClient.validReferralCode(referralCode);
      if (valid) {
        this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("validReferral"));
      } else {
        this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("invalidReferral"));
      }
      return valid;
    },
  },
});
</script>

<style lang="scss">
.signup {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  height: 100%;
  @include medium-up {
    padding: 0 6rem;
  }
}
</style>
