<template>
  <div class="logged-in">
    <InfoBanner class="logged-in__banner-desktop" />

    <div class="logged-in__upper">
      <MobileMenu
        class="logged-in__mobile-menu"
        @click-signout="signOut"
      />
      <Sidebar
        v-if="isLoggedIn"
        class="logged-in__sidebar"
        @click-signout="signOut"
      />
      <InfoBanner class="logged-in__banner-mobile" />
      <router-view
        :key="$route.fullPath"
        v-slot="{ Component }"
      >
        <transition
          name="fade"
          mode="out-in"
        >
          <component :is="Component" />
        </transition>
      </router-view>
      <LoggedOutDialog
        v-if="showLoggedOutDialog"
        :model-value="showLoggedOutDialog"
        @confirm="signOut"
      />
    </div>
    <AppFooter />

    <PreviewAgreementsSideDialog
      v-if="showAgreementsDialog"
      v-model="showAgreementsDialog"
      user-scope
      :portfolios="portfoliosToSignFor"
      preview-mode
    />
    <ReferralsSideDialog v-if="showReferralsDialog" />
    <EsgPaiDialog v-model="showEsgPaiDialog" />
    <PensionDialog v-model="showPensionDialog" />
    <CreatePortfolioDialog v-model="showCreatePortfolioDialog" />
    <YearlyKycDialog
      v-model="showYearlyKycDialog"
      :forced-to-answer="forcedToAnswerKyc"
      @sign-out="signOut"
    />
    <SignAgreementsDialog @agreements="handleShowAgreements" />
    <GetStarted />
    <ConnectBankSideDialog />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import ReferralsSideDialog from "../views/referrals/referrals-side-dialog.vue";
import YearlyKycDialog from "../views/kyc/yearly-kyc-dialog.vue";
import AppFooter from "./footer.vue";
import InfoBanner from "./info-banner.vue";
import SignAgreementsDialog from "./sign-agreements-dialog.vue";
import ConnectBankSideDialog from "./connect-bank-side-dialog.vue";
import CreatePortfolioDialog from "./create-portfolio-dialog.vue";
import PensionDialog from "./pension-dialog.vue";
import store from "@/store";
import Sidebar from "@/components/sidebar/sidebar.vue";
import LoggedOutDialog from "@/components/logged-out-dialog.vue";
import MobileMenu from "@/components/mobile-menu/mobile-menu.vue";
import { NavigationAction } from "@/store/navigation/actions";
import { UserAction } from "@/store/user/action-definitions";
import { PortfolioAction } from "@/store/portfolio/actions";
import { UserMutation } from "@/store/user/mutations";
import type { LegalEntity, PortfolioBase, PortfolioPdfType } from "@/clients";
import PreviewAgreementsSideDialog from "@/components/preview-agreements-side-dialog.vue";
import {
  CURRENT_LEGAL_ENTITY,
  HIDE_ESG_PAI_DIALOG_KEY,
  HIDE_PENSION_DIALOG_KEY,
  HIDE_YEARLY_KYC_UPDATE_KEY,
} from "@/config/general";
import { SignLocation } from "@/store/bankid/types";
import { reloadIfWrongLocale } from "@/utils/locale-url-helper";
import GetStarted from "@/components/get-started.vue";
import { OverviewActions } from "@/store/overview/actions";
import type { TokenUserInfo } from "@/types/user";
import type { NavigationState } from "@/store/navigation/types";
import EsgPaiDialog from "@/components/esg-pai-popup.vue";
import type { UserState } from "@/store/user/types";

export default defineComponent({
  components: {
    AppFooter,
    Sidebar,
    MobileMenu,
    LoggedOutDialog,
    InfoBanner,
    SignAgreementsDialog,
    PreviewAgreementsSideDialog,
    ReferralsSideDialog,
    YearlyKycDialog,
    EsgPaiDialog,
    PensionDialog,
    CreatePortfolioDialog,
    GetStarted,
    ConnectBankSideDialog,
  },
  async beforeRouteEnter(to, from, next) {
    // Does not have access to `this` component instance, so use imported store
    const { isLoggedIn } = store.getters;

    if (isLoggedIn(store.state) === true) {
      const tokenUserInfo: TokenUserInfo = store.getters.tokenUserInfo();

      store.dispatch(OverviewActions.loadPerformances, {
        legalEntitiesPortfolioIds: tokenUserInfo.legalEntitiesPortfolioIds,
      });

      if (!store.state.userStore.currentUser) {
        await store.dispatch(UserAction.loadCurrentUser);
      }

      const legalEntityBrickId = sessionStorage.getItem(CURRENT_LEGAL_ENTITY);
      const legalEntityUser = store.state.userStore.currentUser?.legalEntityUsers.find(
        le => le.legalEntity && le.legalEntity.brickId === legalEntityBrickId,
      );
      const legalEntity = store.state.userStore.currentLegalEntity;
      if (!legalEntity) {
        if (legalEntityBrickId) {
          store.commit(UserMutation.setLegalEntity, legalEntityUser?.legalEntity);
        }
      }
      if (store.state.userStore.currentLegalEntity) {
        reloadIfWrongLocale(
          to.params.locale as string,
          to.fullPath,
          store.state.userStore.currentLegalEntity.countryCode,
        );
      }
      // const { fullName } = store.getters;
      // const { currentLegalEntity } = store.state.userStore;
      // TODO: Not sure how to handle company representatives for hubspot chat yet
      // Disable chat temporarily for them
      // if (fullName && (window as any).$crisp) {
      //   (window as any).$crisp.push([
      //     "set",
      //     "user:nickname",
      //     [
      //       fullName
      //       + (!currentLegalEntity!.isNaturalPerson
      //         ? ` (${legalEntityUser?.companyRepresentativeFirstName} ${legalEntityUser?.companyRepresentativeLastName})`
      //         : ""),
      //     ],
      //   ]);
      // }
      if (!store.state.userStore.missingOrErrorAgreements) {
        store.dispatch(UserAction.getMissingOrErrorAgreements).then(async () => {
          if (
            store.state.userStore.missingOrErrorAgreements
            && (store.state.userStore.missingOrErrorAgreements as PortfolioPdfType[]).filter(
              (a: PortfolioPdfType) => a.error,
            ).length > 0
            && !store.getters.isCompletingSignature(SignLocation.ERROR_AGREEMENTS)
          ) {
            await store.dispatch(
              UserAction.prepareSignAgreements,
              (store.state.userStore.missingOrErrorAgreements as PortfolioPdfType[])
                .filter((a: PortfolioPdfType) => a.error)
                .map((e: PortfolioPdfType) => e.pdfType),
            );
            await store.dispatch(PortfolioAction.loadAllPortfolios);
          }
        });
      }

      await store.dispatch(NavigationAction.loadPortfolios);

      const { portfolios } = store.state.navigationStore as NavigationState;
      const portfolioCount = portfolios ? portfolios.length : 0;
      if (portfolioCount > 1 && to.name && (to.name === "overview" || to.name === "profile")) {
        // Don't show get started overlay on non-portfolio specific views because some of the options,
        // such as "Make a deposit", don't make sense when a portfolio isn't selected
        store.commit(UserMutation.setShowGetStarted, false);
      } else {
        if (
          portfolios
          && portfolioCount === 1
          && to.name
          && (to.name === "overview" || to.name === "profile")
        ) {
          // We need to load PortfolioSettings for get started to show monthly savings. This is otherwise
          // not loaded on these views
          store.dispatch(PortfolioAction.loadPortfolioSettings, {
            legalEntityId: store.state.userStore.currentLegalEntity?.brickId,
            portfolioId: portfolios[0].id,
          });
          store.dispatch(PortfolioAction.loadPortfolioEvents, portfolios[0].id);
        }
        store.commit(UserMutation.setShowGetStarted, true);
      }

      if (to.params) {
        const { id } = to.params;
        if (id) {
          const { token } = store.state.userStore;
          store.dispatch(UserAction.createTokenTimeoutListener, token);
          // The page has reloaded, set the current portfolio to the same.;
          await store.dispatch(PortfolioAction.setActivePortfolio, id);
        }
      }
      next();
    } else {
      next({ name: "login", params: { locale: "se" } });
    }
  },
  data() {
    return {
      showAgreementsDialog: false,
      portfoliosToSignFor: undefined as PortfolioBase[] | undefined,
      showYearlyKycDialog: false,
      showEsgPaiDialog: false,
      showPensionDialog: false,
      showCreatePortfolioDialog: false,
      forcedToAnswerKyc: false,
      hubspotLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "hasPensionPortfolio", "answeredNewEsg"]),
    showLoggedOutDialog(): boolean {
      const { isLoggedIn } = this.$store.getters;
      if (isLoggedIn(this.$store.state) === false) {
        return true;
      }
      return false;
    },
    currentLegalEntity(): LegalEntity | undefined {
      return this.$store.state.userStore.currentLegalEntity;
    },
    showReferralsDialog(): boolean {
      return this.$store.state.userStore.showReferralsDialog;
    },
  },
  mounted() {
    if (this.currentLegalEntity?.isNaturalPerson && (import.meta.env.VITE_ENV === "production" || import.meta.env.VITE_ENV === "staging")) {
      // TODO: We haven't solved how to get the correct name in the chat for companies yet,
      // disable it temporarily
      this.initHubspotChat();
    }

    if (this.currentLegalEntity) {
      const hasPortfolios
        = this.$store.state.navigationStore.portfolios
        && this.$store.state.navigationStore.portfolios.length > 0;
      if (!hasPortfolios) {
        this.showCreatePortfolioDialog = true;
      } else if (
        this.currentLegalEntity.isNaturalPerson
        && !this.answeredNewEsg
        && !sessionStorage.getItem(HIDE_ESG_PAI_DIALOG_KEY)) {
        this.showEsgPaiDialog = true;
      } else if (
        this.currentLegalEntity.isNaturalPerson
        && !this.hasPensionPortfolio
        && this.currentLegalEntity.timesHiddenPensionDialog < 3
        && !sessionStorage.getItem(HIDE_PENSION_DIALOG_KEY)) {
        this.showPensionDialog = true;
      }
    }
    if (!sessionStorage.getItem(HIDE_YEARLY_KYC_UPDATE_KEY) && this.currentLegalEntity) {
      const aYearAgo = DateTime.local().minus({ year: 1 }).toJSDate();
      const thirteenMonthsAgo = DateTime.fromJSDate(aYearAgo).minus({ months: 1 }).toJSDate();

      const aYearAndAHalfAgo = DateTime.local().minus({ year: 1, months: 6 }).toJSDate();
      const twentyOneMonthsAgo = DateTime.fromJSDate(aYearAndAHalfAgo)
        .minus({ months: 3 })
        .toJSDate();
      // KYC date can be empty if user has not created a portfolio
      if (this.currentLegalEntity.kycDate) {
        if (this.currentLegalEntity.riskValue === 3) {
          if (this.currentLegalEntity.kycDate <= aYearAgo) {
            this.showYearlyKycDialog = true;
          }
          if (this.currentLegalEntity.kycDate <= thirteenMonthsAgo) {
            this.showYearlyKycDialog = true;
            this.forcedToAnswerKyc = true;
          }
        } else {
          if (this.currentLegalEntity.kycDate <= aYearAndAHalfAgo) {
            this.showYearlyKycDialog = true;
          }
          if (this.currentLegalEntity.kycDate <= twentyOneMonthsAgo) {
            this.showYearlyKycDialog = true;
            this.forcedToAnswerKyc = true;
          }
        }
      }
    }
  },
  methods: {
    async initHubspotChat() {
      if (!(this.$store.state.userStore as UserState).hubspotChatToken) {
        await this.$store.dispatch(UserAction.getHubspotChatToken);
      }
      (window as any).hsConversationsSettings.identificationEmail = this.currentLegalEntity?.email;
      (window as any).hsConversationsSettings.identificationToken = (this.$store.state.userStore as UserState).hubspotChatToken;

      if ((window as any).HubSpotConversations) {
        /*
        If external API methods are already available, use them.
        */
        this.onConversationsAPIReady();
      } else {
        /*
        Otherwise, callbacks can be added to the hsConversationsOnReady on the (window as any) object.
        These callbacks will be called once the external API has been initialized.
        */
        (window as any).hsConversationsOnReady = [
          this.onConversationsAPIReady,
        ];
      }
    },
    onConversationsAPIReady() {
      (window as any).HubSpotConversations.widget.load();
    },
    handleShowAgreements(portfolios: PortfolioBase[]) {
      this.showAgreementsDialog = true;
      this.portfoliosToSignFor = portfolios;
    },
    async signOut(): Promise<void> {
      this.$store.dispatch(UserAction.signOut);
    },
  },
});
</script>

<style lang="scss" scoped>
.logged-in {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__upper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include medium-up {
      justify-content: center;
      flex-direction: row;
      padding: 1.5rem 5rem 0 5rem;
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.1s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
  }

  &__mobile-menu {
    margin-bottom: 1rem;

    @include medium-up {
      display: none;
    }
  }

  &__sidebar {
    margin-right: 1.5rem;
    display: none;

    @include medium-up {
      display: block;
    }
  }

  &__banner-desktop {
    margin: auto;
    display: none;

    @include medium-up {
      padding: 1.5rem 5rem 0 5rem;
      width: 100%;
      max-width: 93rem;
      display: block;
    }

    @include xlarge-up {
      padding: 1.5rem 3.5rem 0 3.5rem;
    }
  }

  &__banner-mobile {
    width: 100%;

    @include medium-up {
      display: none;
    }
  }
}
</style>
